import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import ReactPlayer from 'react-player'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon
} from "react-share";
import SportsConfessionalAPI from './utils/api';
import './App.css';
import logo from './img/sports-confessional-white2x.png';
import playImage from './img/Play.png';
import checkImage from './img/Check.png';

class Routes extends Component {
  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route path="/view/:sessionId" component={ViewSession} />
            <Route path="/:sessionId" component={App} />
            <Route>
              <NoMatch />
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

class ViewSession extends Component {
  constructor(props) {
    super(props);
    this.api = new SportsConfessionalAPI();
    this.state = {
      videoUrl: null,
      sessionId: this.props.match.params.sessionId
    }
  }

  async componentDidMount() {
    var _this = this;
    try {
      var session = await this.api.getSession(_this.state.sessionId);
      console.log(session);
      if(session.video_url != null) {
        this.setState({
          videoUrl: session.video_url
        });
      }
    } catch(err) {
      console.log(err);
      _this.setState({
        badSession: true
      });
    }
  }

  render() {
    return(
      <div>
        <div>
          <div className="section">
            <div className="container w-container">
              <div className="header">
                <img src={logo} alt="SportsConfessional Logo" className="mobile-logo" />
              </div>
            </div>
          </div>
          <div class="section-4">
            <ReactPlayer url={this.state.videoUrl} controls />
            <h1 class="heading-2">Share your video!</h1>
            <div class="_20-spacer"></div>
            <div>
              <TwitterShareButton url={this.state.videoUrl}>
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>
              &nbsp;&nbsp;
              <FacebookShareButton quote="Check out this video">
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
              &nbsp;&nbsp;
              <RedditShareButton title="Check out this video">
                <RedditIcon size={32} round={true} />
              </RedditShareButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.api = new SportsConfessionalAPI();
    this.acceptTermsAndProvideEmail = this.acceptTermsAndProvideEmail.bind(this);
    this.state = {
      somethingWentWrong: false,
      sessionId: this.props.match.params.sessionId,
      badSession: false,
      acceptTermsAndEmail: false,
      websocket: null,
      socketOpen: false,
      recordLocalState: null,
      recordConfessoinalState: null,
      done: false
    }

  }

  async componentDidMount() {
    // Check if the session exists
    // If it doesn't exist, set the badSession state to true
    // If the session already has a createdVideo, set the badSession state to true
    // If the user is reconnecting and they've already accepted the terms, set the acceptTermsAndEmail state
    var _this = this;
    try {
      var session = await this.api.getSession(_this.state.sessionId);

      // Not sure how we would get here, but it's a "done" session
      if(session.created_video) {
        _this.setState({
          done: true
        });
      }

      // User re-connecting to session
      if(session.accepted_terms) {
        _this.setState({
          acceptTermsAndEmail: true
        });
      }
    } catch(err) {
      console.log(err);
      _this.setState({
        badSession: true
      });
    }
  }

  async acceptTermsAndProvideEmail(email) {
    var _this = this;
    try {
      await _this.api.acceptTermsAndEmail(_this.state.sessionId, email);
      _this.setState({
        acceptTermsAndEmail: true
      });
    } catch(err) {
      console.log(err);
      _this.setState({
        somethingWentWrong: true
      });
    }
  }

  render() {
    var _this = this;

    // Something went wrong
    if(_this.state.somethingWentWrong) {
      return <SomethingWentWrong />;
    }

    // Bad session
    if(_this.state.badSession) {
      return <BadSession />;
    }

    // Has not accepted terms / provided an email
    if(!_this.state.acceptTermsAndEmail) {
      return <TermsAndEmail acceptTermsAndProvideEmail={_this.acceptTermsAndProvideEmail} />
    }

    // All Done, say thanks
    if(_this.state.done) {
      return <Done />
    }

    return <Websocket sessionId={_this.state.sessionId} />
  }
}

class NoMatch extends Component {
  render() {
    return (
      <div>
        <div>
          <div className="section">
            <div className="container w-container">
              <div className="header">
                <img src={logo} alt="SportsConfessional Logo" className="mobile-logo" />
              </div>
            </div>
          </div>
          <div className="section-4">
            <h1 className="heading-2">For more info, email david(at)regentsfield(dot)com</h1>
          </div>
        </div>
      </div>
    )
  }
}

class SomethingWentWrong extends Component {
  render() {
    return (
      <div>Oops, something went wrong. Try again.</div>
    )
  }
}

class BadSession extends Component {
  render() {
    return (
      <div>Bad Session, scan QR code again.</div>
    )
  }
}

class Done extends Component {
  render() {
    return (
      <div>Fin.</div>
    )
  }
}

class TermsAndEmail extends Component {
  constructor(props) {
    super(props);
    this.updateEmail = this.updateEmail.bind(this);
    this.callAcceptTermsAndProvideEmail = this.callAcceptTermsAndProvideEmail.bind(this);
    this.state = {
      email: ''
    }
  }

  updateEmail(ev) {
    this.setState({
      email: ev.target.value
    });
  }

  callAcceptTermsAndProvideEmail(ev) {
    ev.preventDefault();
    var _this = this;
    this.props.acceptTermsAndProvideEmail(_this.state.email);
  }

  render() {
    return (
      <div>
        <div className="section">
          <div className="container w-container">
            <div className="header">
              <img src={logo} alt="SportsConfessional Logo" className="mobile-logo" />
              <h1 className="heading">Terms &amp; Conditions</h1>
            </div>
          </div>
        </div>
        <div className="section-2">
          <div className="container w-container">
            <div className="div-block">
              <p className="basic-paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo</p>
            </div>
            <div className="form-block w-form">
              <form id="email-form" name="email-form" data-name="Email Form" className="form">
                <label for="email" className="field-label">EMAIL ADDRESS</label>
                <input onChange={this.updateEmail} type="email" className="text-field w-input" maxLength="256" name="email" data-name="Email" placeholder="Enter your email address..." id="email" required="" />
                <input onClick={this.callAcceptTermsAndProvideEmail} type="submit" value="Accept Terms" className="submit-button w-button" />
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

let RECORDSTATES = {
  READY: 'ready',
  RECORD: 'record',
  STOP: 'stop',
  PLAYBACK: 'playback',
  DONE: 'done'
}

class Websocket extends Component {
  constructor(props) {
    super(props);
    this.changeStateRecord = this.changeStateRecord.bind(this);
    this.changeStateStop = this.changeStateStop.bind(this);
    this.changeStatePlayback = this.changeStatePlayback.bind(this);
    this.changeStateDone = this.changeStateDone.bind(this);
    this.state = {
      recordState: null,
      socket: null,
      socketOpen: false
    }
  }

  componentDidMount() {
    var _this = this;
    const socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_SERVER);
    _this.setState({
      socket: socket
    });

    socket.addEventListener('open', function(event) {
      console.log('open');
      _this.setState({
        socketOpen: true
      });

      // register this session
      _this.state.socket.send(
        JSON.stringify({
          session: _this.props.sessionId
        })
      );

    });

    socket.addEventListener('close', function(event) {
      console.log('closed');
      _this.setState({
        socketOpen: false
      });
    });

    socket.addEventListener('message', function(message) {
      message = JSON.parse(message.data);
      console.log(message);
      if("currentState" in message) {
        _this.setState({
          recordState: message.currentState
        });
      } else if('session' in message) {
        // Session successfully registered
        // Get current state
        _this.state.socket.send(
          JSON.stringify({
            whatState: true
          })
        );
      }
    });
  }

  changeStateRecord(ev) {
    ev.preventDefault();
    this.state.socket.send(
      JSON.stringify({
        changeState: RECORDSTATES.RECORD
      })
    );
  }

  changeStateStop(ev) {
    ev.preventDefault();
    this.state.socket.send(
      JSON.stringify({
        changeState: RECORDSTATES.STOP
      })
    );
  }

  changeStatePlayback(ev) {
    ev.preventDefault();
    this.state.socket.send(
      JSON.stringify({
        changeState: RECORDSTATES.PLAYBACK
      })
    );
  }

  changeStateDone(ev) {
    ev.preventDefault();
    this.state.socket.send(
      JSON.stringify({
        changeState: RECORDSTATES.DONE
      })
    );
  }

  render() {
    var _this = this;
    // Not done and disconnected. Have them reload the page.
    if(!_this.state.socketOpen && _this.state.recordState !== RECORDSTATES.DONE) {
      return(
        <div>Something went wrong. Please reload the page.</div>
      )
    }

    if(_this.state.recordState === RECORDSTATES.READY) {
      return(
        <div>
          <div className="section">
            <div className="container w-container">
              <div className="header">
                <img src={logo} alt="SportsConfessional Logo" className="mobile-logo" />
              </div>
            </div>
          </div>
          <div className="flex-record-1">
            <div className="container w-container">
              <div className="centered-div">
                <p className="basic-paragraph">When you&#x27;re ready, hit the Record button</p>
                <div className="_20-spacer"></div>
                <a onClick={_this.changeStateRecord} className="link-block w-inline-block" href="">
                  <div className="red-circle"></div>
                  <div className="icon-button-text">RECORD</div>
                </a>
              </div>
            </div>
          </div>
          <div className="container w-container"><a href="#" className="link">Help</a></div>
        </div>
      )
    }

    if(_this.state.recordState === RECORDSTATES.RECORD) {
      return(
        <div>
          <div className="section">
            <div className="container w-container">
              <div className="header">
                <img src={logo} alt="SportsConfessional Logo" className="mobile-logo" />
              </div>
            </div>
          </div>
          <div className="flex-record-1">
            <div className="container w-container">
              <div className="centered-div">
                <p className="basic-paragraph">Recording in Progress.</p>
                <div className="_20-spacer"></div>
                <a href="" onClick={_this.changeStateStop} className="link-block w-inline-block">
                  <div className="red-circle in-progress"></div>
                  <div className="icon-button-text">STOP RECORDING</div>
                </a>
              </div>
            </div>
          </div>
          <div className="container w-container"><a href="#" className="link">Help</a></div>
        </div>
      )
    }

    // If they've stopped recording OR they are playing the video back, they can do all the following
    if(_this.state.recordState === RECORDSTATES.STOP || _this.state.recordState === RECORDSTATES.PLAYBACK) {
      return(
        <div>
          <div>
            <div className="section">
              <div className="container w-container">
                <div className="header">
                  <img src={logo} alt="SportsConfessional Logo" className="mobile-logo" />
                </div>
              </div>
            </div>
            <div className="flex-record-1">
              <div className="container w-container">
                <div className="centered-div">
                  <p className="basic-paragraph">Nice work!<br /><br />What do you want to do with the video?</p>
                  <div className="_20-spacer"></div>
                  <a onClick={_this.changeStatePlayback} href="" className="link-block w-inline-block"><img src={playImage} alt="" className="small-icon-image" />
                    <div className="icon-button-text">PLAY BACK</div>
                  </a>
                  <a onClick={_this.changeStateRecord} href="" className="link-block w-inline-block">
                    <div className="red-circle"></div>
                    <div className="icon-button-text">RE-RECORD</div>
                  </a>
                  <a onClick={_this.changeStateDone} href="" className="link-block dark w-inline-block"><img src={checkImage} alt="" className="small-icon-image" />
                    <div className="icon-button-text blue">SUBMIT VIDEO</div>
                  </a>
                </div>
              </div>
            </div>
            <div className="container w-container"><a href="#" className="link">Help</a></div>
          </div>
        </div>
      )
    }

    // Should signal to parent component that we're done.
    // This shouldn't show
    if(_this.state.recordState === RECORDSTATES.DONE) {
      return(
        <div>
          <div>
            <div className="section">
              <div className="container w-container">
                <div className="header">
                  <img src={logo} alt="SportsConfessional Logo" className="mobile-logo" />
                </div>
              </div>
            </div>
            <div className="section-4"><img src={checkImage} width="73.5" alt="" />
              <h1 className="heading-2">Your video was submitted!</h1>
              <div className="_20-spacer"></div>
              <p className="basic-paragraph light">Share your experience with your friends!</p>
            </div>
          </div>
        </div>
      )
    }

    // In-between state
    return (
      <div>In-Between</div>
    )
  }
}

export default Routes;
