class SportsConfessionalAPI {
  constructor() {
    this.api_base_url = process.env.REACT_APP_API_SERVER;
    this.api_version = 'v1'
  }

  async getSession(sessionId) {
    var _this = this;
    return await fetch(`${_this.api_base_url}/${_this.api_version}/session/${sessionId}/`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(function(response) {
      if(response.status === 200) {
        return response.json();
      } else {
        throw response.status;
      }
    });
  }

  async acceptTermsAndEmail(sessionId, email) {
    var _this = this;
    console.log(sessionId);
    console.log(email);
    return await fetch(`${_this.api_base_url}/${_this.api_version}/session/${sessionId}/accept-terms-and-email/`, {
      method: 'POST',
      body: JSON.stringify({
        email: email
      }),
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(function(response) {
      if(response.status === 204) {
        return true;
      } else {
        throw response.status;
      }
    });
  }
}

export default SportsConfessionalAPI;
